import { HeaderIconLinkRow, InfoCardVariants, Link, MarkdownContent, IconLinkVerticalProps } from "@backstage/core-components"
import { featureFlagsApiRef } from "@backstage/core-plugin-api"
import { IconButton, Grid, Card, CardContent, CardHeader, Divider, Chip } from "@material-ui/core"
import React, { useCallback, useEffect, useState } from "react"
import { EaPackDomainEntityV1beta1, EaPackSystemEntityV1beta1 } from "@internal/plugin-ea-pack-common"
import { makeStyles } from '@material-ui/core/styles';
import { AboutField } from "@backstage/plugin-catalog"
import CachedIcon from '@material-ui/icons/Cached';
import EditIcon from '@material-ui/icons/Edit';

import {
    alertApiRef,
    errorApiRef,
    useApi,
} from '@backstage/core-plugin-api';
import {
    catalogApiRef,
    EntityRefLinks,
    getEntityRelations,
    useEntity,
} from '@backstage/plugin-catalog-react';
import { ANNOTATION_EDIT_URL, parseEntityRef, RELATION_PART_OF, stringifyEntityRef } from "@backstage/catalog-model"
import { TeamPreviewField } from "./TeamPreviewField"
const useStyles = makeStyles({
    gridItemCard: {
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100% - 10px)', // for pages without content header
        marginBottom: '10px',
    },
    gridItemCardContent: {
        flex: 1,
    },
    description: {
        wordBreak: 'break-word',
    },
    avatar: {
        borderWidth: 2,
        borderColor: 'black',
        borderStyle: 'solid',
    },
    avatarItem: {
        marginLeft: "-40px",
    }
});

export const ProductInfoCard = (props: { variant: InfoCardVariants }) => {

    const { variant } = props;
    var entity = useEntity().entity as EaPackSystemEntityV1beta1;

    const classes = useStyles();
    const catalogApi = useApi(catalogApiRef);
    const alertApi = useApi(alertApiRef);
    const errorApi = useApi(errorApiRef);
    const featureFlagsApi = useApi(featureFlagsApiRef);

    const partOfDomainRelations = getEntityRelations(entity, RELATION_PART_OF, { kind: 'domain' });

    var [subDomain, setSubDomain] = useState<EaPackDomainEntityV1beta1 | undefined>(undefined);

    useEffect(() => {
        (async () => {
            if (entity.spec.domain) {
                const subDomainEntity = await catalogApi.getEntityByRef({ name: entity.spec.domain, kind: "Domain", namespace: "default" });
                if (subDomainEntity) {
                    setSubDomain(subDomainEntity as EaPackDomainEntityV1beta1);
                }
            }
        })();
    }, [catalogApi]);



    const refreshEntity = useCallback(async () => {
        try {
            await catalogApi.refreshEntity(stringifyEntityRef(entity));
            alertApi.post({
                message: "Product refresh scheduled",
                severity: 'info',
                display: 'transient',
            });
        } catch (e) {
            errorApi.post(e as Error);
        }
    }, [catalogApi, entity, alertApi, errorApi]);

    return (<Card className={classes.gridItemCard}>
        <CardHeader
            title={`${entity.metadata.title ?? entity.metadata.name ?? ""} at a glance`}
            action={
                <>
                    <IconButton aria-label="Refresh" title="Refresh" onClick={refreshEntity} >
                        <CachedIcon />
                    </IconButton>
                    <IconButton component={Link} aria-label="Edit" title="Edit" to={entity.metadata.annotations?.[ANNOTATION_EDIT_URL] ?? '#'} >
                        <EditIcon />
                    </IconButton>
                </>
            }
        />
        <Divider />
        <CardContent className={classes.gridItemCardContent}>
            <Grid container>
                {/* <AboutField
                    label={t('aboutCard.ownerField.label')}
                    value={t('aboutCard.ownerField.value')}
                    gridSizes={{ xs: 12, sm: 6, lg: 4 }}
                >
                    {ownedByRelations.length > 0 && (
                        <EntityRefLinks entityRefs={ownedByRelations} defaultKind="group" />
                    )}
                </AboutField> */}
                <AboutField label={"Domain"} gridSizes={{ xs: 12, sm: 6, lg: 4 }} >
                    {subDomain && subDomain.spec.subdomainOf && (<EntityRefLinks entityRefs={[parseEntityRef(subDomain.spec.subdomainOf, { defaultKind: "domain" })]} defaultKind="domain" />)}
                </AboutField>
                <AboutField label={subDomain ? subDomain.spec.type == "ProductLine" ? "Product Line" : "Sub Domain" : "?"} gridSizes={{ xs: 12, sm: 6, lg: 4 }}                >
                    {partOfDomainRelations.length > 0 && (<EntityRefLinks entityRefs={partOfDomainRelations} defaultKind="domain" />)}
                </AboutField>

                <AboutField label={"Tags"} gridSizes={{ xs: 12, sm: 6, lg: 4 }}
                >
                    {(entity?.metadata?.tags || []).map(tag => (
                        <Chip key={tag} size="small" label={tag} />
                    ))}
                </AboutField>
                <AboutField label={"The team behind"} gridSizes={{ xs: 12 }} >
                    <TeamPreviewField />
                </AboutField>
                <AboutField label={"Description"} gridSizes={{ xs: 12 }} >
                    <MarkdownContent className={classes.description} content={entity?.metadata?.description || "No description provided."} />
                </AboutField>
            </Grid>
        </CardContent>
    </Card>);
}