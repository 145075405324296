import React, { useEffect, useState } from "react";
import Alert from '@material-ui/lab/Alert';
import { Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import {
  parseEntityRef,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import { featureFlagsApiRef, useApi, identityApiRef, BackstageUserIdentity, configApiRef } from "@backstage/core-plugin-api"
import { EaPackSystemEntityV1beta1 } from "@internal/plugin-ea-pack-common";
import { useEntity } from "@backstage/plugin-catalog-react";
import { PlatformEnablementCard } from "./PlatformEnablementCard";
import { SnykEnablementCard } from "./SnykEnablementCard";
import { AzureEnablementCard } from "./AzureEnablementCard";

export const ManageProductContent = () => {
  const entity = useEntity().entity as EaPackSystemEntityV1beta1;
  const isPlatformEnabled = entity.metadata.annotations?.['pandora.net/platform-enabled'] == "true";
  const idApi = useApi(identityApiRef);
  var [identity, setIdentity] = useState<BackstageUserIdentity | undefined>(undefined);
  var [isOwner, setIsOwner] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      const id = await idApi.getBackstageIdentity();
      setIdentity(id);
      const entityOwnerRef = stringifyEntityRef(parseEntityRef(entity.spec.owner, { defaultKind: "group", defaultNamespace: "default" })).toLowerCase();
      id.ownershipEntityRefs.find(ref => ref.toLowerCase() === entityOwnerRef) ? setIsOwner(true) : setIsOwner(false);
    })();
  }, [idApi]);

  const configApi = useApi(configApiRef);
  const surveys = configApi.getOptional<{ id: number; title: string; collectLink: string; }[]>('soundcheck.collectors.surveyhero.surveys');

  
  return (
    <Grid container spacing={3} alignItems="stretch">
      <Grid item md={12}>
        {!isOwner && <Alert severity="info">This product is in read-only mode as you are not the owner of this product.</Alert>}
      </Grid>
      <Grid item md={12}><PlatformEnablementCard isOwner={isOwner} isPlatformEnabled={isPlatformEnabled} /></Grid>
      <Grid item md={6}><AzureEnablementCard isOwner={isOwner} isPlatformEnabled={isPlatformEnabled} /></Grid>
      <Grid item md={6}><SnykEnablementCard isOwner={isOwner} isPlatformEnabled={isPlatformEnabled} /></Grid>
      <Grid item md={6}>
        <Card>
          <CardHeader title="Host in 'K8s'" />
          <CardContent><code>Coming soon!</code></CardContent>
        </Card>
      </Grid>
      <Grid item md={6}>
        <Card>
          <CardHeader title="Integrate events with Nexus" />
          <CardContent><code>Coming soon!</code></CardContent>
        </Card>
      </Grid>
      <Grid item md={6}>
        <Card>
          <CardHeader title="Integrate APIs with PONG" />
          <CardContent><code>Coming soon!</code></CardContent>
        </Card>
      </Grid>
      <Grid item md={6}>
        <Card>
          <CardHeader title="Analyze data with Olympus" />
          <CardContent><code>Coming soon!</code></CardContent>
        </Card>
      </Grid>
      <Grid item md={6}>
        <Card>
          <CardHeader title="Observe with NewRelic" />
          <CardContent><code>Coming soon!</code></CardContent>
        </Card>
      </Grid>
      <Grid item md={6}>
        <Card>
          <CardHeader title="Alert with OpsGenie" />
          <CardContent><code>Coming soon!</code></CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}