import { Progress, Avatar } from "@backstage/core-components"
import { Grid } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { EaPackSystemEntityV1beta1 } from "@internal/plugin-ea-pack-common"
import { makeStyles } from '@material-ui/core/styles';

import {
    useApi,
} from '@backstage/core-plugin-api';
import {
    catalogApiRef,
    getEntityRelations,
    useEntity,
} from '@backstage/plugin-catalog-react';
import { RELATION_OWNED_BY, stringifyEntityRef, UserEntity } from "@backstage/catalog-model"
import { PandoraGrey } from "@internal/plugin-pandora-react";
const useStyles = makeStyles({
    avatar: {
        borderWidth: 2,
        borderColor: PandoraGrey,
        borderStyle: 'solid',
        boxShadow: '1px 1px 1px #B7b8b9'
    },
    avatarItem: {
        marginLeft: "-40px",
    }
});

export const TeamPreviewField = () => {

    var entity = useEntity().entity as EaPackSystemEntityV1beta1;

    const classes = useStyles();
    const catalogApi = useApi(catalogApiRef);
    const ownedByRelations = getEntityRelations(entity, RELATION_OWNED_BY, { kind: 'group' });
    var [teamMembers, setTeamMembers] = useState<UserEntity[] | undefined>(undefined);

    useEffect(() => {
        (async () => {
            if (ownedByRelations.length > 0) {
                const membersList = await catalogApi.getEntities({
                    filter: {
                        kind: 'User',
                        [`relations.memberof`]: [
                            stringifyEntityRef(ownedByRelations[0]),
                        ],
                    },
                });
                setTeamMembers(membersList.items.map(m => m as UserEntity));
            }
        })();
    }, [catalogApi]);

    return (teamMembers ?
        <Grid container style={{ marginTop: "8px" }}> {teamMembers.map((m, i) => (<Grid item key={i} className={i === 0 ? "" : classes.avatarItem}>
            <Avatar displayName={m.spec.profile?.displayName} picture={m.spec.profile?.picture} classes={{ avatar: classes.avatar }} />
        </Grid>))}
        </Grid>
        : <Progress />
    );
}