import React, { useEffect, useState } from 'react';
import { Grid, List, ListItem, Paper, Typography } from '@material-ui/core';
import {
  Content,
  ContentHeader,
  PageWithHeader,
  SupportButton,
} from '@backstage/core-components';
import { useApi } from "@backstage/core-plugin-api";
import { EntityRefLink, catalogApiRef } from '@backstage/plugin-catalog-react';
import { BusinessCapabilityEntityV1beta1KindName, BusinessCapabilityEntityV1beta1 } from '@internal/plugin-ea-pack-common';

interface BusinessCapabilityWithChildren extends BusinessCapabilityEntityV1beta1 {
  children?: BusinessCapabilityWithChildren[]
}


export interface BusinessCapabilityPageProps {
  message?: string;
}

export function BusinessCapabilityPage(props: BusinessCapabilityPageProps) {
  const [entityTree, setEntityTree] = useState<BusinessCapabilityWithChildren[]>([])
  const catalogApi = useApi(catalogApiRef);
  const businessCapabilitySorter = (a: BusinessCapabilityWithChildren, b: BusinessCapabilityWithChildren) => (a.metadata?.title ?? "").localeCompare(b.metadata?.title ?? "");

  useEffect(() => {
    const fetchData = async () => {
      const x = await catalogApi.getEntities({
        filter: [{ kind: [BusinessCapabilityEntityV1beta1KindName] }],
      });
      const items = x.items as BusinessCapabilityWithChildren[];

      function mapRecursive(nodes: BusinessCapabilityWithChildren[], all: BusinessCapabilityWithChildren[]) {
        for (const node of nodes) {
          node.children = all.filter(i => i.spec.parent === node.metadata.name).sort(businessCapabilitySorter);
          mapRecursive(node.children, all);
        }
      }

      const l1 = items.filter(i => i.spec.level === 1);
      mapRecursive(l1, items);
      setEntityTree(l1);
    };

    fetchData();
  }, [catalogApi]);

  const RenderL2BusinessCapability = (l2: BusinessCapabilityWithChildren) => {
    return (<Paper style={{ padding: "8px" }}>
      <EntityRefLink entityRef={l2}><Typography variant='h6'>{l2.metadata.title}</Typography></EntityRefLink>
      <List dense>
        {l2.children && l2.children.length > 0 ? l2.children.map((l3, i) => {
          const productCount = l3.relations?.filter(x => x.targetRef.indexOf("system") >= 0).length ?? 0;
          return (<ListItem key={i}>
            {productCount === 0 ?
            <EntityRefLink entityRef={l3} title={`${l3.metadata.title}`} style={{color: "#FF5630"}} />:
            <EntityRefLink entityRef={l3} title={`${l3.metadata.title} (${productCount})`} />}
            
          </ListItem>);
        }) : <Typography variant='body1'>No L3 Business Capabilities</Typography>}
      </List>
    </Paper>)

  }

  const RenderL1BusinessCapability = (l1: BusinessCapabilityWithChildren) => {
    return (<Paper style={{ padding: "8px", backgroundColor: l1.spec.color }}>
      <EntityRefLink entityRef={l1}><Typography variant='h4' gutterBottom>{l1.metadata.title}</Typography></EntityRefLink>
      <Grid container direction="column"      >
        {l1.children && l1.children.length > 0 ? l1.children.map((l2, i) => (<Grid item xs={12}>
          <RenderL2BusinessCapability key={i} {...l2} />
        </Grid>)) : <Typography variant='body1'>No L2 Capabilities</Typography>}
      </Grid>
    </Paper>)

  }

  return (<PageWithHeader title="Business Capability Map" themeId="home">
    <Content>
      <ContentHeader title="Browse business capabilities and related systems">
        <SupportButton>All your software catalog entities</SupportButton>
      </ContentHeader>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        {entityTree.map((l1, i) => (<Grid item key={i} xl={3} lg={4} md={6} xs={12}>
          <RenderL1BusinessCapability {...l1} />
        </Grid>))}
      </Grid>
    </Content>
  </PageWithHeader>)
    ;
}
