import React, { useEffect, useState } from "react";
import { Card, CardContent, CardHeader, Grid, SvgIcon, Typography } from '@material-ui/core';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { catalogApiRef, useEntity } from "@backstage/plugin-catalog-react";
import { EaPackSystemEntityV1beta1, ProductAddonEntityV1beta1 } from "@internal/plugin-ea-pack-common";
import { PandoraGrey, PandoraGreen } from "@internal/plugin-pandora-react";
import { LinkButton } from "@backstage/core-components";
import { useApi } from "@backstage/core-plugin-api";

export const AzureEnablementCard = (props: { isOwner: boolean, isPlatformEnabled: boolean }) => {
  const { isOwner, isPlatformEnabled } = props;
  const entity = useEntity().entity as EaPackSystemEntityV1beta1;


  const enableNowLink = `/create/templates/default/create-azure-subscription?formData={"product"%3A"${encodeURIComponent(entity.metadata.name)}"}`;
  const AzureIcon = (p: { enabled: boolean }) => <SvgIcon style={{ fill: p.enabled ? PandoraGreen : PandoraGrey, height: 54, width: 32 }} viewBox="0 0 48 48">
    <path fill="#035bda" d="M46 40L29.317 10.852 22.808 23.96 34.267 37.24 13 39.655zM13.092 18.182L2 36.896 11.442 35.947 28.033 5.678z"></path>
  </SvgIcon>

  const catalogApi = useApi(catalogApiRef);
  var [azSubCount, setAzSubCount] = useState<number | undefined>(undefined);

  useEffect(() => {
    (async () => {
      const subscriptions = await catalogApi.getEntities({ filter: { kind: 'resource', 'spec.system': entity.metadata.name, 'spec.type': 'AzureSubscription' } });
      setAzSubCount(subscriptions.items.length);
    })();
  }, [catalogApi]);


  return (
    <Card>
      <CardHeader title="Build with Azure" />
      <CardContent>
        <Grid container direction="row">
          <Grid item>
            <AzureIcon enabled={isPlatformEnabled} />
          </Grid>
          <Grid item alignContent="stretch">
            {!isPlatformEnabled && <Typography variant="body1">This product must be enabled for Platform Engineering before Azure Subscriptions can be added</Typography>}
            {isPlatformEnabled && <Typography variant="body1">This product currently has {azSubCount ? azSubCount : "?"} Azure Subscriptions.</Typography>}
            {isPlatformEnabled && isOwner && <LinkButton to={enableNowLink} variant="outlined" style={{ backgroundColor: PandoraGreen }}>Request new Azure Subscription 🚀</LinkButton>}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}