import { InfoCard, InfoCardVariants, Progress } from "@backstage/core-components"
import { discoveryApiRef, fetchApiRef, useApi } from "@backstage/core-plugin-api"
import { useEntity } from "@backstage/plugin-catalog-react"
import { IconButton, Grid, Button, Typography } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { PandoraBlue, PandoraGrey, PandoraGreen } from "@internal/plugin-pandora-react"
import JiraIcon from "@material-ui/icons/ListAlt"
import Alert from "@material-ui/lab/Alert";




export const DesignIntegrityStatsCard = (props: { variant: InfoCardVariants }) => {

    const { variant } = props;
    var entity = useEntity().entity;
    var discoApi = useApi(discoveryApiRef);

    var fetchApi = useApi(fetchApiRef);

    var [data, setData] = useState<any | null>(null);
    var [dataLoadFailed, setDataLoadFailed] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            const baseUrl = await discoApi.getBaseUrl("design-integrity");
            let r: Response | null = null;
            switch (entity.kind) {
                case "Domain":
                    r = await fetchApi.fetch(`${baseUrl}/stats?domain=${entity.metadata.name}`);
                    break;
                case "System":
                    r = await fetchApi.fetch(`${baseUrl}/stats?product=${entity.metadata.name}`);
                    break;
            }
            if (r && r.ok) {
                var json = await r.json();
                setData(json);
            } else {
                setDataLoadFailed(true);
            }

        })();
    }, [fetchApi, discoApi]);


    if (data === null && !dataLoadFailed) return <Progress />

    const getCount = (status: string, priority: string) => {
        const item: { count: number, score: number } | undefined = (data.byStatusAndPriority as any[]).find(i => i.priority === priority && i.status === status)
        if (item)
            return `${item.score} (${item.count})`;
        return "0";
    }

    const getJiraUrl = (status?: string, priority?: string) => {
        let url = "https://pandoradigital.atlassian.net/jira/core/projects/DIR/issues/?jql=project%20%3D%20%22DIR%22";
        switch (entity.kind) {
            case "Domain":
                url += `%20AND%20"Domain%20and%20Product%20Line%5BSelect%20List%20%28cascading%29%5D"%20%3D%20%22${encodeURIComponent(entity.metadata.title || entity.metadata.name)}%22`;
                break;
            case "System":
                url += `%20AND%20%22Product%5BDropdown%5D%22%20%3D%20%22${encodeURIComponent(entity.metadata.title || entity.metadata.name)}%22`;
                break;
        }

        if (status) {
            url += `%20AND%20status%20%3D%20%22${encodeURIComponent(status)}%22`;
        }
        if (priority) {
            url += `%20AND%20priority%20%3D%20%22${encodeURIComponent(priority)}%22`;
        }
        url += "%20ORDER%20BY%20created%20DESC";

        return url;
    }

    const baseStyle = { width: "100%", fontWeight: 800, justifyContent: "center" } as React.CSSProperties;

    const radiusNW = { borderTopRightRadius: 0, borderBottomRightRadius: 0, borderBottomLeftRadius: 0 };
    const radiusNE = { borderBottomRightRadius: 0, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 };
    const radiusSW = { borderTopRightRadius: 0, borderBottomRightRadius: 0, borderTopLeftRadius: 0 };
    const radiusSE = { borderTopRightRadius: 0, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 };
    const radiusMID = { borderTopRightRadius: 0, borderBottomRightRadius: 0, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 };

    const highPriority = { opacity: 1.0 };
    const mediumPriority = { opacity: 0.8 };
    const lowPriority = { opacity: 0.6 };


    return (<InfoCard
        title="Design Integrity"
        variant={variant}
        action={
            <IconButton aria-label="Go to Jira" title="Schedule entity refresh" href={getJiraUrl()} target="_blank"            >
                <JiraIcon />
            </IconButton>
        }
    >
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="body1">Design Integrity measure the distance between the current architectural state the the target.
                    The Impact Score is a relative indicator of the impact of not meeting the target state. Lower is better.</Typography>
                <Typography variant="body1">Click on matrix below to dive into specific Design Integrity Deviations.</Typography>
            </Grid>
            <Grid item xs={12}>
                {dataLoadFailed ? <Alert severity="warning">Data could not be loaded from Jira. Please try again</Alert> :
                    <Grid container item >
                        <Grid container spacing={0} direction="column" xs={2}>
                            <Grid item>&nbsp;</Grid>
                            <Grid item><Button variant="text" size="small" style={{ width: "100%", justifyContent: "left" }} href={getJiraUrl("To Do")} target="_blank">To Do</Button></Grid>
                            <Grid item><Button variant="text" size="small" style={{ width: "100%", justifyContent: "left" }} href={getJiraUrl("In Progress")} target="_blank">In Progress</Button></Grid>
                            <Grid item><Button variant="text" size="small" style={{ width: "100%", justifyContent: "left" }} href={getJiraUrl("Done")} target="_blank">Done</Button></Grid>
                        </Grid>
                        <Grid container spacing={0} direction="column" xs={2}>
                            <Grid item style={{ textAlign: 'center' }}><Typography variant="h6" style={{ textTransform: "uppercase" }}>High</Typography></Grid>
                            <Grid item><Button variant="contained" size="small" color="secondary" style={{ backgroundColor: PandoraGrey, ...highPriority, ...radiusNW, ...baseStyle }} href={getJiraUrl("To Do", "High")} target="_blank">{getCount("To Do", "High")}</Button></Grid>
                            <Grid item><Button variant="contained" size="small" color="secondary" style={{ backgroundColor: PandoraBlue, ...highPriority, ...radiusMID, ...baseStyle }} href={getJiraUrl("In Progress", "High")} target="_blank">{getCount("In Progress", "High")}</Button></Grid>
                            <Grid item><Button variant="contained" size="small" color="secondary" style={{ backgroundColor: PandoraGreen, ...highPriority, ...radiusSW, ...baseStyle }} href={getJiraUrl("Done", "High")} target="_blank">{getCount("Done", "High")}</Button></Grid>
                        </Grid>
                        <Grid container spacing={0} direction="column" xs={2}>
                            <Grid item style={{ textAlign: 'center' }}><Typography variant="h6" style={{ textTransform: "uppercase" }}>Medium</Typography></Grid>
                            <Grid item><Button variant="contained" size="small" color="primary" style={{ backgroundColor: PandoraGrey, ...mediumPriority, ...radiusMID, ...baseStyle }} href={getJiraUrl("To Do", "Medium")} target="_blank">{getCount("To Do", "Medium")}</Button></Grid>
                            <Grid item><Button variant="contained" size="small" color="primary" style={{ backgroundColor: PandoraBlue, ...mediumPriority, ...radiusMID, ...baseStyle }} href={getJiraUrl("In Progress", "Medium")} target="_blank">{getCount("In Progress", "Medium")}</Button></Grid>
                            <Grid item><Button variant="contained" size="small" color="primary" style={{ backgroundColor: PandoraGreen, ...mediumPriority, ...radiusMID, ...baseStyle }} href={getJiraUrl("Done", "Medium")} target="_blank">{getCount("Done", "Medium")}</Button></Grid>
                        </Grid>
                        <Grid container spacing={0} direction="column" xs={2}>
                            <Grid item style={{ textAlign: 'center' }}><Typography variant="h6" style={{ textTransform: "uppercase" }}>Low</Typography></Grid>
                            <Grid item><Button variant="contained" size="small" color="primary" style={{ backgroundColor: PandoraGrey, ...lowPriority, ...radiusNE, ...baseStyle }} href={getJiraUrl("To Do", "Low")} target="_blank">{getCount("To Do", "Low")}</Button></Grid>
                            <Grid item><Button variant="contained" size="small" color="primary" style={{ backgroundColor: PandoraBlue, ...lowPriority, ...radiusMID, ...baseStyle }} href={getJiraUrl("In Progress", "Low")} target="_blank">{getCount("In Progress", "Low")}</Button></Grid>
                            <Grid item><Button variant="contained" size="small" color="primary" style={{ backgroundColor: PandoraGreen, ...lowPriority, ...radiusSE, ...baseStyle }} href={getJiraUrl("Done", "Low")} target="_blank">{getCount("Done", "Low")}</Button></Grid>
                        </Grid>
                        <Grid container spacing={0} direction="column" xs={4} justifyContent="space-between">
                            <Grid item xs style={{ textAlign: 'center' }}><Typography variant="h6" style={{ textTransform: "uppercase" }}>Total score</Typography></Grid>
                            <Grid item xs style={{ textAlign: 'center' }}><Typography variant="h1" style={{ fontWeight: 800 }}>{data.totalScore}</Typography></Grid>
                        </Grid>
                    </Grid>}
            </Grid>
        </Grid>
    </InfoCard>)
}