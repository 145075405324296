import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { AdminPage, ContributePage, CustomKindsPicker, EntityColumCustomizer, BusinessCapabilityPage, ExplorePage } from '@internal/plugin-ea-pack-react';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage-community/plugin-tech-radar';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';

import { AlertDisplay, OAuthRequestDialog, SignInPage } from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { UnifiedThemeProvider } from '@backstage/theme';
import { CssBaseline } from '@material-ui/core';
import { pandoraTheme } from '@internal/plugin-pandora-react';
import { HomepageCompositionRoot } from '@backstage/plugin-home';
import { CustomizableHomePage } from './components/home/CustomizableHomePage';
import { VisitListener } from '@backstage/plugin-home';
import { configApiRef, microsoftAuthApiRef, useApi } from '@backstage/core-plugin-api';
import { ApiPage } from '@internal/plugin-ea-pack-react/src/components/ApiPage';
import { ChatBot } from '@internal/plugin-ea-pack-react/src/components/ChatBot';
import { EntityKindPicker, EntityTypePicker, UserListPicker, EntityOwnerPicker } from '@backstage/plugin-catalog-react';
import { SoundcheckRoutingPage } from '@spotify/backstage-plugin-soundcheck';


const app = createApp({
  apis,
  components: {
    SignInPage: props => (
      <SignInPage
        {...props}
        auto
        providers={[useApi(configApiRef).getString('auth.environment') === 'development' ? 'guest' : {
          id: 'microsoft-auth-provider',
          title: 'Pandora',
          message: 'Sign in using your Corporate credentials',
          apiRef: microsoftAuthApiRef,
        }]}
      />
    ),
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  themes: [
    {
      id: 'pandora',
      title: 'Pandora',
      variant: 'light',
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={pandoraTheme} noCssBaseline>
          <CssBaseline />
          {children}
        </UnifiedThemeProvider>
      ),
    },
  ],
  featureFlags: [
    { name: "design-integrity", description: "Show Design Integrity score on Product Pages", pluginId: '' },
    { name: "platform-engineering", description: "Show the new Platform Engineering Flow", pluginId: '' },
    { name: "soundcheck", description: "Show Soundcheck", pluginId: '' }
  ]
});


const routes = (
  <FlatRoutes>
    <Route path="/" element={<Navigate to="home" />} />
    <Route path="/home" element={<HomepageCompositionRoot />}>
      <CustomizableHomePage />
    </Route>
    <Route path="/explore" element={<ExplorePage />} />
    <Route path="/catalog" element={<CatalogIndexPage
      columns={EntityColumCustomizer}
      ownerPickerMode='all'
      initialKind='system'
      actions={[]}
      filters={
        <>
          <CustomKindsPicker />
          <EntityKindPicker hidden initialFilter='system' />
          <EntityTypePicker />
          <UserListPicker />
          <EntityOwnerPicker />
        </>
      } />} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
      </TechDocsAddons>
    </Route>
    <Route path="/capabilitymap" element={<BusinessCapabilityPage />} />
    <Route path="/create" element={<ScaffolderPage templateFilter={template => !template.metadata.tags?.includes("hidden")} />} />
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route path="/apis" element={<ApiPage />} />
    <Route
      path="/tech-radar"
      element={<TechRadarPage width={1500} height={800} />}
    />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/contribute" element={<ContributePage />} />
    <Route path="/admin" element={<AdminPage />} />
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route path='/soundcheck' element={<SoundcheckRoutingPage title='Pandora Soundcheck' />}
    />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <ChatBot />
    <AppRouter>
      <VisitListener />
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
