import { Typography } from '@material-ui/core';
import React from 'react';
import {
    createApiFactory,
    discoveryApiRef,
    configApiRef,
    useApi
  } from '@backstage/core-plugin-api';

export const ReleaseNotesPage = () => {

    const configApi = useApi(configApiRef);

    const ver = configApi.getOptionalString('app.version');


    return (
        <>
            <Typography variant='h2'>Release notes</Typography>
            <Typography variant='body1'>App Version: {ver}</Typography>
            <Typography variant='body1'>Release notes will show up here</Typography>
        </>
    );
};